<template>
  <section class="m-moobyyoho m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="Official" >
      <div slot="right">
        <!-- <img src="@/assets/public/icon_search.png"  alt="search" style="width:20px;height:20px;margin:6px"> -->
        <router-link to="/en/cart">
          <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="top-img">
          <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
      </div>
    </m-header>

    <!-- 头部大图 -->
    <img v-lazy="bannerList[0].pic_url" alt="img" class="header-img" @click="$router.push(bannerList[0].link)">
    <div class="classify m-padding">
      <div class="grid-2">
          <router-link :to="bannerList[1].link ? bannerList[1].link : ''"><img v-lazy="bannerList[1].pic_url" alt="img"></router-link>
          <router-link :to="bannerList[2].link ? bannerList[2].link : ''"><img v-lazy="bannerList[2].pic_url" alt="img"></router-link>
      </div>
      <div class="grid-1">
          <router-link :to="bannerList[3].link ? bannerList[3].link : ''"><img v-lazy="bannerList[3].pic_url" alt="img"></router-link>
      </div>
    </div>

    <!-- 滚动切换 -->
    <van-tabs :offset-top="tabTop" title-active-color="#ED2A24" title-inactive-color="#000000" v-model="activeSectionId"
    style="position:sticky;top:0px;z-index:99;margin-bottom:10px">
      <van-tab :title="item.title_en" v-for="(item, index) in classifyList" :key="index" :name="item.id">
      </van-tab>
    </van-tabs>
    <goods-columns v-if="classifyList[activeIndex]" @cartClick="handleCart" :data="goodsList" :columns="classifyList[activeIndex].row_pro_num"></goods-columns>
    <p class="search-no" v-if='noMore'>No More Products</p>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>



    <!-- 筛选弹窗 -->
    <van-popup v-model="filter" get-container="#app"  position="right" close-on-popstate class="filter-order" >
      <p class="filter-title van-hairline--bottom">时间筛选</p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 1}" @click="handleMonth(1)">最近一个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 3}" @click="handleMonth(3)">最近三个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 6}" @click="handleMonth(6)">最近六个月</span></p>
    </van-popup>

  </section>
</template>

<script>
import MHeader      from '@/components/en/m-header.vue'
import MDot         from '@/components/en/m-dot.vue'
import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'

import { getMoobyClassify, getClassifyGoods, getMoobyBanner } from '@/api/en/mooby.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ MHeader, MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      tab:0,          // tabs选中的面板
      filter:false,   // 筛选面板显示
      month:1,        // 筛选的结果 
      recomnd:4,
      loading:false,
      skuModal:false,
      start:false,
      start_dom:null,
      tabTop:44,
      dotAnimate:false,
      cartNum:0,
      classifyList: [],
      goodsList: [],
      activeSectionId: '',
      page: 1,
      noMore: false,
      bannerList: [{},{},{},{}],
      activeIndex: 0,
      goodsId: '',
      scrollTop: 0
    }
  },

  mounted(){
    this.getMoobyClassifyHandle()
    this.getMoobyBannerHandle()

    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.scrollTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getClassifyGoodsHandle()
        }
      }
    },
    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getMoobyBannerHandle() {
      // 获取自营专区Banner
      getMoobyBanner(this.$route.query.id).then(res => {
        if(res) {
          this.bannerList = res.data
        }
      })
    },
    getMoobyClassifyHandle() {
      // 获取自营专区分类
      getMoobyClassify(this.$route.query.id).then(res => {
        if(res) {
          if (this.$store.state.isMiniProgram) {
              this.classifyList = res.data.filter(item => {
                    return  item.id != 56 && item.id != 57 && item.id != 58
                });
          }else{
              this.classifyList = res.data.filter(item => {
                return item.status == 1
              })
          }
          if(res.data.length > 0) {
            this.activeSectionId = this.classifyList[0].id
            // this.getClassifyGoodsHandle()
          }
        }
      })
    },
    getClassifyGoodsHandle() {
      // 获取自营专区分类下的商品
      this.islock = true
      getClassifyGoods({type_id: this.activeSectionId, page: this.page,cid:this.$route.query.id}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
      })
    }
  },
  watch: {
    activeSectionId(val) {
      if(val) {
        this.classifyList.forEach((el, index) => {
          if(el.id == val) {
            this.activeIndex = index
          }
        })
        this.goodsList = []
        this.page = 1
        this.islock   = false
        this.noMore = false
        this.getClassifyGoodsHandle()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top-img {
  width:20px;
  height:20px;
  margin:6px;
}
.search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
@import './style.less';
</style>