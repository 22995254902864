import { render, staticRenderFns } from "./cart-flex.vue?vue&type=template&id=20dcf270&scoped=true&"
var script = {}
import style0 from "./cart-flex.vue?vue&type=style&index=0&id=20dcf270&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20dcf270",
  null
  
)

export default component.exports