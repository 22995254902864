<template>
  <span class="m-dot" :class="{'m-dot-2':$store.state.cart.length == 2}">
    {{$store.state.cart}}
    <span class="dot-animate animate" :style="{'opacity': animate  ? 1 : 0}"></span>
    </span>
</template>

<script>
export default {
  name: "MDot",
  props: {
    animate: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style lang="less" scoped>
@import './../../untils/css/minxin.less' ;
.m-dot {
    background-color: #f56c6c;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    top: 5px;
    right: 6px;
    font-size: 10px;
    transform: translateY(-50%) translateX(100%);
    .dot-animate {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
    }
    .animate {
      .m-wave-show(200%,200%,red,-7px,-9px)
    }
}
.m-dot-2{
  padding: 0 3px;
}

</style>