/** 
 *   自营专区
 */
import { get } from '@/untils/js/axios.js'

// 获取分类显示
export const getMoobyClassify = id=> get(`/special/type-list/${id}`)

// 获取分类显示下的商品
export const getClassifyGoods = params => get('/special/goods-list', params)

// 获取分类Banner
export const getMoobyBanner = id => get(`/special/banner-list/${id}`)

// 获取天天福利分类
export const getWelfareList = id=> get(`/special/type-list/${id}`)

