const mixin = {
    beforeRouteLeave(to, form, next) {
        let scrollTopList = this.cacheData = window.localStorage.getItem('scrollTop') ? JSON.parse(window.localStorage.getItem('scrollTop')) : {}
        scrollTopList[form.name] = this.$refs.scroll.scrollTop
        window.localStorage.setItem('scrollTop', JSON.stringify(scrollTopList))
        next()
    },
    activated() {
        this.cacheData = window.localStorage.getItem('scrollTop') ? JSON.parse(window.localStorage.getItem('scrollTop')) : null
        if(this.cacheData) {
            this.$refs.scroll.scrollTop = this.cacheData[this.$route.name]
        }
    }
}

export default mixin